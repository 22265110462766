// Colors
$primary-accent: #003666;
$primary-accent-200: #006ac7;
$secondary-accent: #c48e13;

$neutral-200: #e2e0e0;
$neutral-800: #1a1a1a;
$neutral-900: #0a0a0a;

//  Mixins:
@mixin media_query($min-width: 1024px) {
  @media(min-width: $min-width) {
    @content;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
video,
canvas,
svg {
  max-width: 100%;
  display: block;
}


/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/******** General **********/

.desktop-only {
  display: none;

  @include media_query(1000px) {
    display: block;
  }

}

.phone-only {
  display: block;
  
  @include media_query(1000px) {
    display: none;
  }
  
}


/******** Content ************/



.header {

  height: 100vw * .5;

  @include media_query(1000px) {
    height: 100vw * .52;
  }
}

.bg {
  position: absolute;
  filter: brightness(40%)
}


.hero-bg {
  background-color: rgba($neutral-900, 80%);

  // border-radius: 0px 0px 80px 80px;
  min-height: 175px;;
  // height: 50vw * .3;
  padding-top: 2rem;
  padding-bottom: 0.75rem;
  transform: translateX(-50%);

  // min-width: 300px;
  width: 100%;

  left: 50%;

  @include media_query(700px) {
    border-radius: 40px 40px 60px 60px;

    transform: translateX(-50%);

    left: 50%;
    top: 25%;

    min-width: 500px;
    width: 50%;
    max-width: 1000px;
    min-height: 220px;
    // max-height: 150px;
    padding: 3rem;

  }


  position: absolute;

  z-index: 5;

}


.title {
  font-size: 1.75rem;
  font-weight: bold;
  color: $neutral-200;
  margin-inline: auto;
  text-align: center;

  max-width: 65%;

  @include media_query(700px) {
    font-size: 2.5rem;
  }

}

.blurb {
  text-align: center;
  margin-top: 0.5rem;
  color: $neutral-200;

}

.navbar {
  position: absolute;
  width: 60%;
  z-index: 10;

  top: 110%;
  right: 50%;
  transform: translateX(50%);
  max-width: 1000px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;


  li {
    list-style: none;
    padding: 1rem;
  }

  // a {
  //   color: colors.$neutral-200;
  //   text-decoration: none;
  //   font-weight: 700;
  //   font-size: 1.15rem;
  //   text-align: center;

  //   &:hover {
  //     color: colors.$secondary-accent;

  //     .nav-hover-text {
  //       display: none;
  //     }
  //   }
  // }

  // @include media_query(1000px) {

  // }
}

.nav-image {
  width: 50px;
  height: 50px;

  filter: invert(75%);

  &:hover {
    scale: 125%;
    filter: brightness(0) saturate(100%) invert(52%) sepia(86%) saturate(457%) hue-rotate(4deg) brightness(93%) contrast(90%);
  }


  @include media_query(800px) {
    top: 70%;
  }


}

.nav-hover-text {
  color: $neutral-200;
  text-align: center;
}

.nav-mobile{
  position: relative;
  top: 5%;
  left: 50%;
  z-index: 10;

  transform: translateX(-50%);


  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  li {
    list-style: none;
    padding: 1rem;
  }


    a {
    color: $secondary-accent;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.15rem;
    text-align: center;

    &:hover {
      color: $secondary-accent;

      .nav-hover-text {
        display: none;
      }
    }
  }

  @include media_query(700px){
    // top: 2%;
  }

}


.support-sticky {
  position: fixed;
  z-index: 20;

  filter: invert(75%);
  height: 50px;
  width: 50px;
  bottom: 10%;
  right: 2%;

  &:hover {
    scale: 150%;
    filter: brightness(0) saturate(100%) invert(52%) sepia(86%) saturate(457%) hue-rotate(4deg) brightness(93%) contrast(90%);

    &::after {
      content: ""
    }

  }

  &::after {
    content: "Support"
  }
}

// .hamburger-menu {
//   //TODO, may need to make some changes. like a button.. for extra padding, etc.
//   position: absolute;
//   top: 16px;
//   right: 20px;
//   max-width: 30px;

//   z-index: 100;
// }


.about-aoa {
  background-color: rgba($neutral-900, 90%);
  margin-top: 1rem;
  padding-inline: 3rem;

  @include media_query(1000px) {
    padding: 4rem 0;

  }

  a {
    color: $secondary-accent;

    // Create Hover
  }
}

.about-aoa-container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  max-width: 800px;
  margin-inline: auto;
  text-align: center;
  color: rgba($neutral-200, 75%);


  h1 {
    color: $neutral-200;
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
}



footer {
  font-weight: bold;
  padding: 0.75rem 2rem;
  background-color: $neutral-900;
  color: rgba($neutral-200, 75%)
}

.footer-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  @include media_query(1000px) {
    flex-direction: row;
  }

}

.credit-dessence {

  font-weight: 100;
  min-width: 11rem;

  font-size: 0.8rem;
  margin-top: 1rem;
  align-self: center;

  @include media_query(1000px) {
    align-self: flex-end;
    margin-top: 0;
    font-size: 1rem;
  }
}


// //  ************* ALT PAGES

// .alt-header {
//   height: 100px;
//   width: 100%;

//   @include media_query(1000px) {
//     height: 150px;
//     width: 100%;
//     background-color: rgba($neutral-900, 90%);
//     text-align: center;

//     padding-bottom: 2rem;
//   }
// }

// .alt-title {
//   font-size: 2.5rem;
//   font-weight: bold;
//   color: $neutral-200;
//   margin: auto;
//   padding-top: 1rem;

//   max-width: 80%;

// }

// .alt-blurb {
//   margin-top: 0.5rem;
//   color: $neutral-200;

// }

// .alt-navbar {
//   position: absolute;
//   left: 75%;
//   top: 0%;
//   z-index: 10;

//   height: 150px;
//   width: 300px;
//   align-items: center;

//   display: flex;
//   justify-content: space-around;

//   li {
//     list-style: none;
//     padding: 1rem;
//   }

//   a {
//     color: white;
//     text-decoration: none;
//     font-weight: 700;
//     font-size: 1.15rem;
//     text-align: center;

//     &:hover {
//       color: $secondary-accent;

//       // .alt-nav-hover-text {
//       //   display: none;
//       // }
//     }
//   }
// }

// .alt-nav_image {
//   display: none;
// }

// .lore-container {
//   h1 {
//     font-size: 1.25rem;
//     margin-top: 1.5rem;
//   }

//   p {
//     margin-left: 2rem;
//   }

//   padding: 2rem 5rem;
//   width: 1000px;
// }

// .body-page {
//   display: grid;
//   grid-template-columns: 1fr 5fr;

// }

// .body-page-content {
//   background-color: $neutral-200;
//   font-size: 1rem;
// }

// .sidebar {
//   background-color: rgba($neutral-900, 80%);

//   h1 {
//     font-size: 1.5rem;
//     text-align: center;
//     margin-top: 2rem;
//     color: $neutral-200;

//     &::after {
//       content: "";
//       display: block;
//       width: 100px;
//       height: 2px;
//       background-color: black;
//       margin-inline: auto;
//     }
//   }
// }
